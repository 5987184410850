/*
  Copyright 2018-2020 National Geographic Society

  Use of this software does not constitute endorsement by National Geographic
  Society (NGS). The NGS name and NGS logo may not be used for any purpose without
  written permission from NGS.

  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Popper from '@material-ui/core/Popper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import concat from 'lodash/concat';
import compact from 'lodash/compact';
import {
  AnalysisResultStatusEnum,
  AnalysisTypeEnum,
  IAnalysisResult,
} from 'modules/analysis/model';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons as VizzIcons } from 'vizzuality-components';
import { replace } from 'redux-first-router';
import { ErrorBoundary, QUERIES } from '@marapp/earth-shared';
import { bbox } from '@turf/turf';

import { useAuth0 } from '../../auth/auth0';
import CollectionNew from '../../components/collection/collection-new';
import CollectionsCard from '../../components/collection/collections-card';
import { ELSACard } from '../../components/elsa/';
import Header from '../../components/header';
import LastViewedPlace from '../../components/last-viewed-place';
import LayerConfigError from '../../components/layer-config-error';
import Layers from '../../components/layers';
import Map from '../../components/map';
import Places from '../../components/places';
import FeaturedPlaces from '../../components/places/featured-places';
import Sidebar from '../../components/sidebar';
import Url from '../../components/url';
import {
  MAP_ENABLE_ELSA,
  MAP_SIDEBAR_WIDTH,
  MAP_SHOW_DISCLAIMER,
  AI_API_URL,
  AI_API_KEY,
} from '../../config';
import { useAnalyses, useAnalysisResult, useLayers } from '../../fetchers';
import { ILastViewedPlace, INotification } from '../../modules/global/model';
import { EarthRoutes } from '../../modules/router/model';
import { EPanels } from '../../modules/sidebar/model';
import CollectionDetails from './collection-details';
import PlaceDetails from './place-details';
import { URL_PROPS } from './url';
import { PreviewLayer } from '../../utils/preview';

import IOChat from '../../components/chat';

import interceptClicks from 'intercept-link-clicks';

const useStyles = makeStyles((theme) => {
  const minTabHeight = theme.spacing(4.5);

  return {
    root: {
      background: theme.palette.grey['900'],
      flex: 1,
      position: 'relative',
      width: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
    },
    content: {
      position: 'relative',
      width: '100%',
      height: '100vh',
      zIndez: 0,
    },
    mapContainer: (props: any) => ({
      height: props.showExpansionContent ? 'calc(100vh - 64px)' : '100%',
      width: '100%',
      transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    tabContainer: {
      backgroundColor: theme.palette.grey['600'],
    },
    tabs: {
      minHeight: minTabHeight,
      '& button': {
        minWidth: 0,
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
        minHeight: minTabHeight,
      },
    },
    tooltip: {
      background: '#616161',
      opacity: '90%',
      borderRadius: 4,
      padding: '4px 8px',
      cursor: 'pointer',
    },
  };
});

interface IProps {
  setLayersActive?: (payload: any) => void;
  setLocationHighlight?: (payload: any) => void;
  setMapBounds?: (payload: any) => void;
  setMapViewport?: (payload: any) => void;
  setNotification?: (payload: any) => void;
  setPlacesSearch?: (payload: any) => void;
  setPreview?: (payload: any) => void;
  setSidebarPanel?: (payload: any) => void;
  setSidebarPanelExpanded?: (payload: any) => void;
  setSidebarOpen?: (o: boolean) => void;
  sidebar: {
    open: boolean;
    panel?: EPanels;
    panelExpanded: boolean;
  };
  page?: string;
  notification?: INotification;
  mapStyle?: string;
  layers?: any;
  layersPanel?: boolean;
  lastViewedPlace?: ILastViewedPlace;
  latestResult?: string;
  selected?: string;
  collection?: any;
  router?: any;
}

const { EARTH, COLLECTION, LOCATION, NEW_COLLECTION } = EarthRoutes;

const EarthPage = (props: IProps) => {
  const {
    setLayersActive,
    setLocationHighlight,
    setMapBounds,
    setMapViewport,
    setNotification,
    setPlacesSearch,
    setPreview,
    setSidebarPanel,
    setSidebarPanelExpanded,
    setSidebarOpen,
    sidebar: { panel, open: sidebarOpen },
    notification,
    router,
    lastViewedPlace,
    latestResult,
    layers,
    mapStyle,
  } = props;
  const { type } = router;
  const { i18n, t } = useTranslation();
  const { userData, getAccessToken, privateGroups, selectedGroup, isAuthenticated } = useAuth0();
  const [token, setToken] = useState('');
  const [isMapReloading, setMapReloading] = useState(false);
  const { data: activeLayers } = useLayers(QUERIES.LAYER.getActive(layers.active.join(';')));
  const { data: groupsWithELSA } = useAnalyses(
    QUERIES.ANALYSIS.getAllFiltered(selectedGroup.join(','), 'ELSA')
  );
  const { data: fetchedResult } = useAnalysisResult(
    latestResult,
    QUERIES.ANALYSIS.getOne(selectedGroup.join(','))
  );

  const theme = useTheme();
  const selectedOpen = [LOCATION, COLLECTION, NEW_COLLECTION].includes(type);
  const withHeaderLayout = [EARTH, LOCATION, COLLECTION].includes(type);
  const newCollectionLayout = [NEW_COLLECTION].includes(type);
  const canCreateCollections = !!privateGroups.length;
  const showLastViewedPlace =
    lastViewedPlace && selectedGroup.includes(lastViewedPlace.organization);
  const showELSA = MAP_ENABLE_ELSA && !!groupsWithELSA?.length;

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const showExpansionContent = type === LOCATION && isSmallDevice;
  const classes = useStyles({ ...props, showExpansionContent });

  useEffect(() => {
    async function fetchToken() {
      const accessToken = await getAccessToken();
      setToken(() => accessToken);
    }
    if (isAuthenticated) {
      fetchToken();
    }
  }, [isAuthenticated, getAccessToken]);

  // Custom watcher that monitors changes in it's dependency list
  // Each time such a dependency changes, a new MapBox instance will be created
  useEffect(() => {
    setMapReloading(true);
    // List of dependencies that trigger a new instance of the map (map reload)
  }, [mapStyle, i18n.language]);

  // 'Workaround flag' - dictates a new render cycle with the new MapBox instance
  useEffect(() => {
    isMapReloading && setMapReloading(false);
  }, [isMapReloading]);

  useEffect(() => {
    setSidebarOpen(!isSmallDevice);
  }, [isSmallDevice]);

  useEffect(() => {
    const shouldCloseSidenav = isSmallDevice && (type === LOCATION || type === EARTH);

    if (shouldCloseSidenav) {
      setSidebarOpen(false);
    }
  }, [type, isSmallDevice]);

  useEffect(() => {
    // reset sidebar panel to first of possible choices when page type changes
    if (!Object.values(EPanels).includes(panel)) {
      setSidebarPanel(EPanels.PLACES);
    }
  }, [type]);

  useEffect(() => {
    if (!fetchedResult) {
      return;
    }
    if (
      fetchedResult.status === AnalysisResultStatusEnum.COMPLETE &&
      fetchedResult.type === AnalysisTypeEnum.ELSA
    ) {
      setNotification(t('Your ELSA Map is ready'));
    }
  }, [fetchedResult]);

  // create temporary preview layer
  let previewLayer;
  if (layers.preview) {
    previewLayer = PreviewLayer(layers.preview);
    layers.active = [previewLayer.slug];
  }

  interceptClicks(
    {
      sameOrigin: false,
    },
    (e, el) => {
      try {
        const url = new URL(el.href);
        e.preventDefault();
        if (url.host === window.location.host) {
          const params = Object.fromEntries(url.searchParams);
          if ('layers' in params) {
            const layers = params['layers'].split(',');
            setLayersActive(layers);
          } else {
            setLayersActive(['']);
          }
          if ('coordinates' in params) {
            const coordinates = params['coordinates'].split(',');
            setMapViewport(coordinates);
          }
          if ('geojson' in params) {
            const geojson = JSON.parse(atob(params['geojson']));
            const payload = {
              id: 'temp',
              geojson: geojson,
            };
            setMapBounds({ bbox: bbox(geojson) });
            setLocationHighlight(payload);
          }
          const newUrl = `${url.pathname}${url.search}`;
          replace(newUrl);
        } else {
          console.log(url);
          window.open(url, '_blank');
        }
      } catch (error) {
        console.error(error);
      }
    }
  );

  return (
    <main className={`${classes.root} marapp-qa-earth marapp-qa-pageearth`} role="main">
      <Sidebar selectedOpen={selectedOpen}>
        {withHeaderLayout && (
          <>
            <VizzIcons />
            <Url type="EARTH" urlProps={URL_PROPS} />

            <Header setNotification={setNotification} />

            <IOChat baseUrl={AI_API_URL} apiKey={AI_API_KEY} user={userData} token={token} />

            <div style={{ display: 'none' }}>
              <Box px={2} className={classes.tabContainer}>
                <Tabs
                  className={classes.tabs}
                  textColor="primary"
                  value={panel}
                  onChange={(_, newValue) => setSidebarPanel(newValue)}
                >
                  {[EARTH, LOCATION, COLLECTION].includes(type) && (
                    <Tab
                      label={t('Places')}
                      value={EPanels.PLACES}
                      className="marapp-qa-places-tab"
                    />
                  )}
                  {[EARTH, LOCATION, COLLECTION].includes(type) && (
                    <Tab
                      label={t('Layers')}
                      value={EPanels.LAYERS}
                      className="marapp-qa-layers-tab"
                    />
                  )}
                </Tabs>
              </Box>

              {type === EARTH && (
                <>
                  {panel === EPanels.PLACES && (
                    <Places selected={selectedOpen}>
                      <>
                        {showLastViewedPlace && (
                          <LastViewedPlace place={lastViewedPlace} group={selectedGroup} />
                        )}
                        <CollectionsCard group={selectedGroup} canCreate={canCreateCollections} />
                        <FeaturedPlaces
                          group={selectedGroup}
                          setPlacesSearch={setPlacesSearch}
                          setSidebarPanelExpanded={setSidebarPanelExpanded}
                        />
                      </>
                    </Places>
                  )}
                  {panel === EPanels.LAYERS && (
                    <Layers selected={selectedOpen} preview={previewLayer}>
                      {showELSA && (
                        <ELSACard
                          group={selectedGroup}
                          canCreate={showELSA} // TODO, should this be limited to users with a CREATE_ELSA privilege?
                          layers={layers}
                          setMapLayer={setPreview}
                        />
                      )}
                    </Layers>
                  )}
                </>
              )}
            </div>

            {type === LOCATION && <PlaceDetails panel={panel} selected={selectedOpen} />}
            {type === COLLECTION && <CollectionDetails panel={panel} selected={selectedOpen} />}
          </>
        )}

        {newCollectionLayout && <CollectionNew privateGroups={privateGroups} router={router} />}
      </Sidebar>

      <div className={classes.content}>
        <div className={classes.mapContainer}>
          <Popper
            className={classes.tooltip}
            open={!!notification}
            onClick={() => {
              setNotification(false);
            }}
            style={{
              left: (sidebarOpen ? MAP_SIDEBAR_WIDTH : 0) + 40,
              top: 16,
            }}
          >
            <Typography variant="body2">{notification}</Typography>
          </Popper>
          <ErrorBoundary fallbackComponent={<LayerConfigError selectedOpen={selectedOpen} />}>
            {!isMapReloading && (
              <Map
                page={props.page}
                selectedOpen={selectedOpen}
                t={t}
                activeLayers={
                  previewLayer ? compact(concat(activeLayers, previewLayer)) : activeLayers
                }
                showDisclaimerDefault={MAP_SHOW_DISCLAIMER}
              />
            )}
          </ErrorBoundary>
        </div>
      </div>
    </main>
  );
};

export default EarthPage;
