import '@nlux/themes/nova.css';
import '../../styles/io-chat-custom.css';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { AiChat } from '@nlux/react';

import { useChatAdapter } from './useChatAdapter';
import { SessionStorage } from '@marapp/earth-shared';

interface IProps {
  baseUrl: string;
  apiKey: string;
  user: any;
  token: string;
  setLayersActive: (any) => void;
  setLocationHighlight: (any) => void;
  setMapBounds: (any) => void;
}

const IOChat = (props: IProps) => {
  const { baseUrl, apiKey, user, token } = props;

  const authHeaders = useMemo(
    () => ({
      'x-api-key': apiKey,
    }),
    [apiKey]
  );

  const [conversationHistory, setConversationHistory] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [threadId, setThreadId] = useState('');

  // Get data from sessionStorage on component mount
  // useEffect(() => {
  //   let storedThreadId = sessionStorage.getItem('thread_id');
  //   if (storedThreadId) {
  //     setThreadId(storedThreadId);
  //   } else {
  //     fetch(`${baseUrl}/threads`, {
  //       method: 'POST',
  //       body: JSON.stringify({})
  //     }).then((response) => {
  //       return response.json();
  //     }).then((data) => {
  //       storedThreadId = data["thread_id"];
  //       setThreadId(storedThreadId);
  //     })
  //   }
  // }, []); // Empty dependency array ensures it runs only once on mount

  // Set data to sessionStorage whenever 'data' changes
  // useEffect(() => {
  //   sessionStorage.setItem('thread_id', threadId);
  // }, [threadId]);

  useEffect(() => {
    async function getChatHistory() {
      const storedThreadId = sessionStorage.getItem('thread_id');
      if (storedThreadId) {
        setThreadId(storedThreadId);
      } else {
        fetch(`${baseUrl}/threads`, {
          method: 'POST',
          headers: authHeaders,
          body: JSON.stringify({ metadata: { user_id: user.nickname } }),
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            const newThreadId = data['thread_id'];
            sessionStorage.setItem('thread_id', newThreadId);
            setThreadId(newThreadId);
          });
      }
      if (threadId) {
        const response = await fetch(`${baseUrl}/threads/${threadId}/history`, {
          method: 'GET',
          headers: authHeaders,
        });
        return await response.json();
      }
    }
    getChatHistory().then((data) => {
      if (data) {
        if (Array.isArray(data) && data.length) {
          const conversationHistory = data[0].values.messages.reduce(function (filtered, entry) {
            if (['human', 'ai'].includes(entry.type) && entry.content) {
              filtered.push({
                message: entry.content,
                role: entry.type === 'human' ? 'user' : 'assistant',
              });
            }
            return filtered;
          }, []);
          setConversationHistory(conversationHistory);
        } else {
          console.log('getChatHistory data', data);
        }
      }
      setLoaded(true);
    });
  }, [threadId]);

  const inputPreProcessor = useCallback(
    (input, conversationHistory) => {
      // rename conversationHistory to how langserve expects chat_history
      return {
        assistant_id: 'fe096781-5601-53d2-b2f6-0d3403f7e9ca',
        input: {
          messages: [
            {
              role: 'human',
              content: input,
            },
          ],
        },
        config: {
          configurable: {
            token: token,
          },
        },
      };
    },
    [token, user]
  );

  const outputPreProcessor = useCallback((output) => {
    return output.at(-1).content;
  }, []);

  const adapter = useChatAdapter({
    url: `${baseUrl}/threads/${sessionStorage.getItem('thread_id')}/runs`,
    headers: authHeaders,
    inputPreProcessor: inputPreProcessor,
    outputPreProcessor: outputPreProcessor,
    useInputSchema: false,
    dataTransferMode: 'batch',
  });

  const messageReceivedCallback = useCallback((message) => {
    console.log(message);
  }, []);

  if (!isLoaded) {
    return <div>Loading</div>;
  }

  return (
    <AiChat
      adapter={adapter}
      className="ioChat"
      events={{
        messageReceived: messageReceivedCallback,
      }}
      personaOptions={{
        assistant: {
          name: "",
          avatar: '/static/bob_avatar.png',
          tagline: `
          Hello, I'm Bob, your junior geospatial analyst! 
          I can find satellite imagery, request specialized analytics, and provide 
          context on recent events to help understand our changing world. Once we 
          identify your areas of interest, I'll create maps, analyze changes, and 
          explore possible causes. Feel free to ask about the data and analytics 
          I have, or pick a quick start prompt. 
          How can I help?
          `,
          // 'Your AI Geospatial Sidekick',
        },
        user: {
          name: user.name || 'User',
        },
      }}
      conversationOptions={{
        conversationStarters: [
          {
            label: 'Getting Started',
            prompt: 'What can you help me with?',
          },
          {
            label: 'Find a location',
            prompt: 'Search for Santa Fe County, NM',
          },
          {
            label: 'Get order history',
            prompt: 'What was my last order?',
          },
        ],
        // autoScroll: true,
        layout: 'bubbles',
      }}
      messageOptions={{
        markdownLinkTarget: 'self',
      }}
      initialConversation={conversationHistory.length > 0 ? conversationHistory : null}
      promptBoxOptions={{
        autoFocus: true,
        placeholder: 'Type your message here',
      }}
      displayOptions={{
        height: '100%',
        width: '100%',
      }}
    />
  );
};
export default IOChat;
